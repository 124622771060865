import "./App.css";
import React, { Fragment, useState, useEffect } from "react";
import NavBar from "./components/NavBar";
import {
  Center,
  Box,
  Heading,
  useColorModeValue,
  CSSReset,
  ColorModeProvider,
  Link,
  ThemeProvider
} from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import StatsGridWithImage from "./components/Intro";
import SignupCard from "./components/SignUp";
import NotFound from "./components/Page404";
import Dashb from "./components/Dashb";



export default function App(props) {
  const [jwt, setJWT] = useState("");
  const [loc, setLoc] = useState("");

  useEffect(() => {
    let t = window.localStorage.getItem("jwt");
    if (t) {
      if (jwt === "") {
        setJWT(JSON.parse(t));
      }
    }
  }, [jwt])

  function handleJWTChange(jwt) {
    // console.log("handleJWTChange:", jwt);
    setJWT(jwt);
  }

  function logout() {
    setJWT("");
    window.localStorage.removeItem("jwt");
  }

  function handleLocChange(loc) {
    setLoc(loc);
  }

  const darkModeToggle = document.querySelector('#togbutton');
  // console.log('darkmodetoggle', darkModeToggle);


  let loginLink;
  let loginLinkS;
  if (jwt === "") {
    loginLink = <Link to="/login">sign in</Link>;
    loginLinkS = '/login';
    // console.log("loginLinkS", loginLinkS)
  } else {
    loginLink = (
      <Link to="/logout" onClick={logout}>
        logout
        </Link>
    );
    loginLinkS='/home';
    // console.log("loginLinkS", loginLinkS)
  }

  return (
    <Fragment>
        <CSSReset />
      <NavBar jwt={jwt} loginlinkS={loginLinkS} logout={logout} handleJWTChange={handleJWTChange}/>
      
      <Router>
        <Switch>
          <Route exact path="/home">
            <StatsGridWithImage />
            {/* <SignupCard loc={loc} handleLocChange={handleLocChange}/> */}
          </Route>

          <Route exact path="/signup">
            <SignupCard loc={loc} handleLocChange={handleLocChange}/>
          </Route>

          <Route exact path="/dashboard">
            <Dashb />
            </Route>

          <Route exact path="/">
          <StatsGridWithImage />
            {/* <SignupCard loc={loc} handleLocChange={handleLocChange}/> */}
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
}
