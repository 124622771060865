import RetoolApp from "./RetoolCard";
import { Fragment, ReactNode } from 'react';
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';

export default function Dashb() {
  return (
      <Fragment>
    <Flex bg={'gray.800'} position={'relative'} height="800px">
      {/* <Flex
        flex={1}
        // zIndex={0}
        // display={{ base: 'none', lg: 'flex' }}
        backgroundSize={'cover'}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position={'absolute'}
        width={'100%'}
        insetY={0}
        right={0}
        >
        <Flex
          bgGradient={'linear(to-r, gray.800 10%, transparent)'}
          w={'full'}
          h={'full'}
        />
      </Flex> */}
      <RetoolApp height="1000px"></RetoolApp>
      {/* <Container maxW={'7xl'} zIndex={10} position={'relative'}>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Stack
            flex={1}
            color={'gray.400'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 20, xl: 60 }}>
            <Box mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={'heading'}
                fontWeight={700}
                textTransform={'uppercase'}
                mb={3}
                fontSize={'xl'}
                color={'gray.500'}>
                Technology
              </Text>
              <Heading
                color={'white'}
                mb={5}
                fontSize={{ base: '3xl', md: '5xl' }}>
                Loyalty Points Tied To Digital Assets
              </Heading>
              <Text fontSize={'xl'} color={'gray.400'}>
                The Tikeo™ technology allows your customer to monitor their loyalty points and get
                complete insights in real time. The proprietary
                software/hardware ecosystem enable a quick intregration to your platform.
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={'heading'}
                    fontSize={'3xl'}
                    color={'white'}
                    mb={3}>
                    {stat.title}
                  </Text>
                  <Text fontSize={'xl'} color={'gray.400'}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
          <Flex flex={1} />
        </Stack>
      </Container> */}
    </Flex>
    </Fragment>
  );
}


const StatsText = ({ children }: { children: ReactNode }) => (
    <Text as={'span'} fontWeight={700} color={'white'}>
      {children}
    </Text>
  );

const stats = [
    {
      title: '10+',
      content: (
        <>
          <StatsText>Software modules</StatsText> for detailed monitoring and
          real-time analytics
        </>
      ),
    },
    {
      title: '24/7',
      content: (
        <>
          <StatsText>Analytics</StatsText> enabled right in your dashboard without
          history limitations
        </>
      ),
    },
    {
      title: '13%',
      content: (
        <>
          <StatsText>Users</StatsText> in North America have chosen Tikeo™ as
          their management solution
        </>
      ),
    },
    {
      title: '250M+',
      content: (
        <>
          <StatsText>Users</StatsText> currently connected and monitored by the
          Tikeo™ software
        </>
      ),
    },
  ];