import React, {Fragment} from "react";

import { Button, Flex, useColorMode, useColorModeValue, IconButton, IconButtonProps } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa"

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">

const Toggle = (props: ColorModeSwitcherProps) => {
    const { toggleColorMode } = useColorMode("dark")
    const text = useColorModeValue("dark", "light")
    const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  
    return (
        <Fragment>
      <IconButton
        id="togbutton"
        size="md"
        fontSize="lg"
        variant="ghost"
        color="current"
        marginLeft="2"
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        aria-label={`Switch to ${text} mode`}
        {...props}
      />
      </Fragment>
      
//   const { colorMode, toggleColorMode } = useColorMode();

//   return (
//     <Fragment>
//       {/* <Flex align="center" justify="center" height="100vh" direction="column"> */}
//         <Button size="md" onClick={() => toggleColorMode()}>
//          {colorMode}
//         </Button>
//       {/* </Flex> */}
//       </Fragment>
  );
};

export default Toggle;