import { Fragment, ReactNode } from 'react';
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  useColorModeValue
} from '@chakra-ui/react';
import RetoolApp from './RetoolCard';

export default function StatsGridWithImage() {
  return (
    <Flex 
        //minH={'100vh'}
        //minW={'300vh'}
         
        align={'center'}
        justify={'center'}
        
        bg={useColorModeValue('white', 'gray.800')}>
          <Stack
            // color={'gray.400'}
            // spacing={8} mx={'auto'} maxW={'lg'} py={12} px={0} w='100%' maxWidth='820px' align={'center'}>
              spacing={8} mx={'auto'} maxW={'lg'} py={12} px={0} w='100%' maxWidth='820px'>
                <Stack align={'center'}>

            <Box  py={1}>
              <Heading
                // mb={0}
                spacing = {1}
                align={'center'}
                fontSize={{ base: '3xl', md: '4xl' }}>
                Welcome to Tikeo Technologies!
              </Heading>
              
              {/* <Text fontSize={'xl'} color={'gray.400'} py={14}>
                
              </Text> */}
              
            </Box>
            <Box py={12} >
              <Heading
                // mb={0}
                spacing = {1}
                align={'center'}
                fontSize={{ base: '2xl', md: '1xl' }}>
                Enhance your business through blockchain technologies
                </Heading>
                </Box>

                <Box>
<Fragment>
              <div class="container">
			<div class="baton-0"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-1"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-2"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-3"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-4"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-5"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-6"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-7"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-8"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-9"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-10"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-11"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-12"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-13"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-14"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-15"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-16"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-17"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-18"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-19"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-20"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-21"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-22"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-23"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-24"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-25"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-26"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-27"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-28"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-29"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-30"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-31"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-32"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-33"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-34"><div class="metronome"><div class="baton"></div></div></div>
			<div class="baton-35"><div class="metronome"><div class="baton"></div></div></div>
</div>
              </Fragment>
</Box>

                <Box py={12} >
              <Heading
                // mb={0}
                spacing = {1}
                align={'center'}
                fontSize={{ base: '1xl', md: '1xl' }}>
                Our locations : USA (New York), Asia (Mumbai), France (Paris)
                </Heading>
                </Box>
                <Box py={12} >
              <Heading
                // mb={0}
                spacing = {1}
                align={'center'}
                fontSize={{ base: '1xl', md: '1xl' }}>
                Our industries : retail, transportation, food, hospitality, luxury goods and telecommunications
                </Heading>
                </Box>
                


              
              
              {/* <Text fontSize={'xl'} color={'gray.400'} py={14}>
                
              </Text> */}
            </Stack>
            
          </Stack>
          </Flex>

  );
}

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as={'span'} fontWeight={700} color={'white'}>
    {children}
  </Text>
);

const stats = [
  {
    title: '10+',
    content: (
      <>
        <StatsText>Software modules</StatsText> for detailed monitoring and
        real-time analytics
      </>
    ),
  },
  {
    title: '24/7',
    content: (
      <>
        <StatsText>Analytics</StatsText> enabled right in your dashboard without
        history limitations
      </>
    ),
  },
  {
    title: '13%',
    content: (
      <>
        <StatsText>Users</StatsText> in North America have chosen Tikeo™ as
        their management solution
      </>
    ),
  },
  {
    title: '250M+',
    content: (
      <>
        <StatsText>Users</StatsText> currently connected and monitored by the
        Tikeo™ software
      </>
    ),
  },
];