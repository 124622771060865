import {
    Box,
    Flex,
    Text,
    IconButton,
    Image,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Heading,
    HStack,
  } from '@chakra-ui/react';
  import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  } from '@chakra-ui/icons';
  import { IoIosLink } from "react-icons/io";
  import LinkButton from './LinkButton';
  import { BrowserRouter as Router, Switch, Route, Link as ReactLink, useHistory } from "react-router-dom";
  import TheLogo from "../assets/tikeo.png"
import Toggle from './Toggle';
import React, { Fragment, useEffect } from 'react';
  
  export default function NavBar(props) {
    const { isOpen, onToggle } = useDisclosure();
    // console.log("navbar props:", props)
    const history = useHistory();

    // useEffect(() => {
    //   console.log("login jwt:", props.jwt)
    //   if (props.jwt === "") {
    //     console.log("jwt is empty")
    //     console.log("props history:", props)
    //     props.history.push({
    //         pathname: "/home",
    //       });
    //       return;
    //     }
    // }, [props.history, props.jwt]);

    function handleClick(path) {
      //history.push(path);
      // console.log(path);
      props.logout();
      props.loginlinkS = '/login';
      if (props.jwt === '') {

      }
    }

    return (
      <Fragment>
      <Box>
        <Flex
            position="sticky"
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Text as="span"
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'heading'}
              color={useColorModeValue('gray.800', 'white')}>
                <HStack>
                <Box > <IoIosLink size={34} color={useColorModeValue('green.300', 'green.400')} fontWeight={'bold'}></IoIosLink> </Box>
                {/* <img src={TheLogo} size="sm" alt="co" /> */}
              <Box >
                <Link href='/home' style={{ textDecoration: 'none' }}>
                  <Box fontSize={22} color={useColorModeValue('green.400', 'green.400')} fontWeight={'bold'} fontFamily='monospace'>Tikeo</Box>
              
              </Link>
              {/* <Icon color={'red.400'} w={5} h={5}  as={ChevronRightIcon} /> */}
              </Box>
              </HStack>
            </Text>
  
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav jwt={props.jwt}/>
            </Flex>
          </Flex>
  
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
                <Toggle />

            <Button
            //   display={{ base: 'none', md: 'inline-flex' }}
              as={'a'}
              style={{ textDecoration: 'none' }}
              onClick={() => {handleClick('home');}} 
              fontSize={'sm'}
              fontWeight={600}
              disabled={false}
              color={'white'}
              bg={'purple.500'}
              href={'/signup'}
              _hover={{
                bg: 'gray.600',
                color: 'white',
              }}>
              Contact Us
            </Button>
            {/* </Link> */}
          </Stack>
        </Flex>
  
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
      </Fragment>
    );
  }
  
  const DesktopNav = (props) => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
    // console.log("desktopnav props", props)
    // console.log("desktopnav props", props.jwt)
    // console.log("navitems", NAV_ITEMS)
    return (
      <Fragment>
      <Stack direction={'row'} spacing={4} mt={2.5} >
        {NAV_ITEMS.map((navItem) => (
          (props.jwt !== "" || navItem.label !== "DataP" && navItem.label !== "Points") ? (
          <Box key={navItem.label} >
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={2}
                  href={navItem.href ?? '#'}
                  fontSize={'sm'}
                  fontWeight={700}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }} key={navItem.label}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>) :
          (null)
        ))}
      </Stack>
      </Fragment>
    );
  };
  
  const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
      <Fragment>
      <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('red.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text as="span"
              transition={'all .3s ease'}
              _groupHover={{ color: 'red.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text as="span" fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'red.400'} w={5} h={5} as={ChevronRightIcon} href="url('../assets/nebula.jpg')"/>
          </Flex>
        </Stack>
      </Link>
      </Fragment>
    );
  };
  
  const MobileNav = () => {
    return (
      <Fragment>
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
      </Fragment>
    );
  };
  
  const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();
  
    return (
      <Fragment>
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={Link}
          href={href ?? '#'}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text as="span"
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children.map((child) => (
                <Link key={child.label} py={2} href={child.href}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
      </Fragment>
    );
  };
  
  interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
  }
  
  const NAV_ITEMS: Array<NavItem> = [

    {
      label: 'Dashboard',
      href: '/dashboard',
    },
    // {
    //   label: 'Contact',
    //   href: '/contact',
    // },
  ];