import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    InputLeftElement,
    Stack,
    VStack,
    Button,
    Heading,
    Text,
    Textarea,
    useColorModeValue,
    Link,
  } from '@chakra-ui/react';
  import { BsGithub, BsLinkedin, BsPerson, BsTwitter } from 'react-icons/bs';
  import { MdEmail, MdOutlineEmail } from 'react-icons/md';
  import { useEffect, useRef, useState } from 'react';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
  import emailjs, { init } from '@emailjs/browser';
  
  export default function SignupCard(props) {
    const [fromemail, setFromEmail] = useState("");
    const [fromname, setFromName] = useState("");
    const [fromcompany, setFromCompany] = useState("");
    const [fromipaddress, setFromIpaddress] = useState("");
    const [message, setMessage] = useState("");
    const formContact = useRef();

    const [details, setDetails] = useState(null);

    useEffect(() => {
      // console.log('in useeffect');
      // console.log("city ipaddress", fromipaddress);
      // console.log("city doc ipaddress", document.getElementById("fromipaddress").value);
      // console.log("city prop loc", props.loc);
      if (document.getElementById("fromipaddress").value != "" && document.getElementById("fromipaddress").value !== "city") {
        // console.log("city ok", document.getElementById("fromipaddress").value);
        return details;
      }
      document.getElementById("fromipaddress").value = "city";
    const getUserGeolocationDetails = async () => {
        await fetch(
            "https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0"
        )
            .then(response => 
              response.json())
            // }, (error) => {
            //   console.log(error.text);
            // })
            .then(data => {
              // console.log('got data', data);
              document.getElementById("fromipaddress").value = data.city;
              setFromIpaddress(data.city);
              // console.log('city', data.city);
              // console.log('fromipaddress', fromipaddress);
              props.handleLocChange(data.city);
              setDetails(data);
              // console.log('typeof', typeof details);
              if (details) {
                // console.log(details);
                // console.log(details.city);
                setFromIpaddress(details.city);
                if (details.city) {
                document.getElementById("fromipaddress").value = details.city;
                }
              };
            });
            return details;
    }

    getUserGeolocationDetails()
    .catch(console.error);
  }, []

  )
  
    const sendEmail = async (e) => {
      e.preventDefault();
      // console.log('in sendEmail');
      // console.log('formContact.current:', details);
      // console.log('e.target:', e.target);
      // console.log('formContact.current:', formContact.current);
      // console.log('formContact.current.message:', formContact.current.message);
      

      // const georesult = await getUserGeolocationDetails;
      // console.log('formContact.current.fromipaddress:', formContact.current.fromipaddress);
      
      

      if (details) {
        // console.log('formContact.current.fromemail.value', formContact.current.fromemail.value)
      emailjs.sendForm('service_xaipiee', 'template_n0uy44y', formContact.current, 'nIyAaPeJIb4C-hax_')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset();
      }
    };
  

    function handleFromEmail(evt) {
      //console.log("in handleEmail");
      setFromEmail(evt.target.value);
      //console.log("email:", evt.target.value);
    };

    function handleFromName(evt) {
      //console.log("in handleEmail");
      setFromName(evt.target.value);
      //console.log("email:", evt.target.value);
    };

    function handleFromCompany(evt) {
      //console.log("in handleEmail");
      setFromIpaddress(evt.target.value);
      //console.log("email:", evt.target.value);
    };

    function handleFromIpaddress(evt) {
      //console.log("in handleEmail");
      setFromCompany(evt.target.value);
      //console.log("email:", evt.target.value);
    };

    function handleMessage(evt) {
      //console.log("in handleEmail");
      setMessage(evt.target.value);
      //console.log("email:", evt.target.value);
    };

    return (
      <Flex 
        //minH={'100vh'}
        //minW={'300vh'}
         
        align={'center'}
        justify={'center'}
        
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={0} w='100%' maxWidth='820px'>
          <Stack align={'center'}>
          <Heading fontSize={'2xl'} textAlign={'center'}>
              Contact us to find out how Tikeo Technologies can help your company!
            </Heading>
            <br />
          </Stack>
  

                <form ref={formContact} onSubmit={sendEmail}>     
                <Box
                  bg={useColorModeValue('white', 'gray.700')}
                  borderRadius="lg"
                  p={8}
                  color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                  shadow="base">
                  <VStack spacing={5}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
  
                      <InputGroup>
                        <InputLeftElement children={<BsPerson />} />
                        <Input type="text"
                         name="fromname"
                        //  value={fromname}
                          onChange={handleFromName}
                          placeholder="Your Name" />
                      </InputGroup>
                    </FormControl>
  
                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>
  
                      <InputGroup>
                        <InputLeftElement children={<MdOutlineEmail />} />
                        <Input
                          type="email"
                          name="fromemail"
                          // value={email}
                          onChange={handleFromEmail}
                          placeholder="Your Email"
                        />
                      </InputGroup>
                    </FormControl>
  
                    <FormControl isRequired>
                      <FormLabel>Company</FormLabel>
  
                      <InputGroup>
                        {/* <InputLeftElement children={<MdOutlineEmail />} /> */}
                        <Input
                          
                          name="fromcompany"
                          // value={email}
                          onChange={handleFromCompany}
                          placeholder="Your Company Name"
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl >

                        {/* <InputLeftElement children={<MdOutlineEmail />} /> */}
                        <Input
                          id="fromipaddress"
                          type="hidden"
                          name="fromipaddress"
                          onChange={handleFromIpaddress}
                          // value={email}
                        />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Message</FormLabel>
  
                      <Textarea
                        name="message"
                        // value={message}
                          onChange={handleMessage}
                        placeholder="Your Message"
                        rows={6}
                        resize="none"
                      />
                    </FormControl>
  
                    <Button
                      type='submit'
                      colorScheme="blue"
                      bg="blue.400"
                      color="white"
                      _hover={{
                        bg: 'blue.500',
                      }}
                      isFullWidth>
                      Contact Us
                    </Button>
                  </VStack>
                </Box>
                </form> 
              </Stack>
      </Flex>
    );


  }