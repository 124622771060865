import { Fragment } from 'react';
import Retool from 'react-retool';

function RetoolApp() {
  const sample = { name: 'Sample data' }
  return (
    <Retool 
    //   url="https://retoolin.tryretool.com/embedded/public/f7607e1f-670a-4ebf-9a09-be54cf17181e"
    url="https://tikeotech.retool.com/apps/dash-af"
      data={sample}
    />
    // <Fragment>
    // <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRUTVKfBYe-LXOfrwpv23lkvHxjkjpi5VqLebQ9_GBw1u9epnd2q1kOU7LFb3pv5CMYgCU03oWl1ijM/pubhtml?gid=185747833&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
    // </Fragment>
  );
}

export default RetoolApp;